import React, { useState } from 'react';
import {
  Button, Card, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';
import { toast } from 'react-toastify';
import i18n from 'i18n-js';

import { ModalNames } from '../../../../../constants';
import useIsReserveNowEnabled from '../../../../../hooks/useIsReserveNowEnabled';
import useIsEmbeddedExperienceEnabled from '../../../../../hooks/useIsEmbeddedExperienceEnabled';
import SocialShare from '../../../../global/SocialShare';
import ModalService from '../../../../../services/ModalService';
import { buildQPDirectLink, buildQPReserveNowLink } from '../../../../../utils/quickPossessionUtils';

const i18nOpts = { scope: 'components.admin.quickPossessions.show.directLinkModal.index' };

function onClose() {
  ModalService.close(ModalNames.QUICK_POSSESSION_DIRECT_LINK_MODAL);
}

function onCopy() {
  toast.info(i18n.t('messages.copied', i18nOpts));
  onClose();
}

const DirectLinkModal = ({ opened, quickPossession }) => {
  if (!quickPossession) return null;

  const [isCheckedReserveNow, setIsCheckedReserveNow] = useState(false);
  const [isCheckedEmbeddedExperience, setIsCheckedEmbeddedExperience] = useState(false);
  const isReserveNowEnabled = useIsReserveNowEnabled();
  const isEmbeddedExperienceEnabled = useIsEmbeddedExperienceEnabled();

  const onReserveNowChange = (event) => {
    const isChecked = event.target.checked;
    setIsCheckedReserveNow(isChecked);
  };

  const onEmbeddedExperienceChange = (event) => {
    const isChecked = event.target.checked;
    setIsCheckedEmbeddedExperience(isChecked);
  };

  const { community, model } = quickPossession;

  const buildUrl = () => {
    if (isCheckedReserveNow) {
      return buildQPReserveNowLink(quickPossession, isCheckedEmbeddedExperience);
    }

    return buildQPDirectLink(quickPossession, isCheckedEmbeddedExperience);
  };

  const url = buildUrl();
  const title = i18n.t('title', i18nOpts);
  const name = i18n.t('name', { ...i18nOpts, modelName: model.name, communityName: community.name });

  return (
    <Modal isOpen={opened}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <div className="text-uppercase mb-3">
          {name}
        </div>
        <Card body className="mb-4 px-3 py-2">
          <a href={url} target="_blank" rel="noreferrer" className="word-break-break-all">
            {url}
          </a>
        </Card>

        {isReserveNowEnabled && (
          <FormGroup check className="mb-2">
            <Label check>
              <Input
                type="checkbox"
                name="reserveNow"
                id="reserveNow"
                checked={isCheckedReserveNow}
                onChange={onReserveNowChange}
              />
              {i18n.t('reserveNow', i18nOpts)}
            </Label>
          </FormGroup>
        )}

        {isEmbeddedExperienceEnabled && (
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="embeddedExperience"
                id="embeddedExperience"
                checked={isCheckedEmbeddedExperience}
                onChange={onEmbeddedExperienceChange}
              />
              {i18n.t('embeddedExperience', i18nOpts)}
            </Label>
          </FormGroup>
        )}

        <SocialShare url={url} itemName={name} />
      </ModalBody>
      <ModalFooter>
        <CopyToClipboard text={url} onCopy={onCopy} className="mr-3">
          <Button outline color="primary" className="text-uppercase">
            <MdContentCopy size="1rem" className="mr-2 align-middle" />
            {i18n.t('buttons.copy', i18nOpts)}
          </Button>
        </CopyToClipboard>
        <Button outline color="secondary" onClick={onClose}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DirectLinkModal.propTypes = {};

DirectLinkModal.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.QUICK_POSSESSION_DIRECT_LINK_MODAL]?.opened || false,
  params: store.modals[ModalNames.QUICK_POSSESSION_DIRECT_LINK_MODAL]?.params || {},
  quickPossession: store.quickPossessions.quickPossession
}))(DirectLinkModal);
