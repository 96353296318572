import { useSelector } from 'react-redux';

const useIsEmbeddedExperienceEnabled = () => {
  const { currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany
  }));
  return !!currentCompany.enableEmbedded;
};

export default useIsEmbeddedExperienceEnabled;
