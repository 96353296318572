export const getCellColor = (percentageChange) => {
  if (!percentageChange) return '';

  if (percentageChange >= 20) {
    return 'bg-success-200';
  }

  if (percentageChange <= -20) {
    return 'bg-danger-200';
  }

  return '';
};

export const calculateRate = (numerator, denominator) => {
  if (!denominator || denominator === 0) return 0;
  return ((numerator / denominator) * 100).toFixed(0);
};

export const displayRatePercentage = (rate) => `(${rate}%)`;
