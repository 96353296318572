import React, { useMemo, useState } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/all';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import SecureStore from '../../../../../utils/secureStore';
import { formatNumber } from '../../../../../utils/currencyUtils';
import { calculateRate, displayRatePercentage, getCellColor } from './utils';

const i18nOpts = { scope: 'components.admin.masterAdminDashboard.visitorLeadsTable.listItem.index' };

const ListItem = ({ item }) => {
  const [expanded, setExpanded] = useState(false);

  const onCompanyClick = (company) => {
    SecureStore.setCurrentCompanyId(company.id);
    window.location = '/admin/overview';
  };

  const onToggle = () => {
    setExpanded(!expanded);
  };

  const returningVisitorsRate = useMemo(() => calculateRate(
    item.totalReturningVisitors.currentValue, item.totalVisitors.currentValue
  ), [item.totalReturningVisitors.currentValue, item.totalVisitors.currentValue]);

  const websiteReturningVisitorsRate = useMemo(() => calculateRate(
    item.websiteReturningVisitors.currentValue, item.websiteVisitors.currentValue
  ), [item.websiteReturningVisitors.currentValue, item.websiteVisitors.currentValue]);

  const ownlyReturningVisitorsRate = useMemo(() => calculateRate(
    item.ownlyReturningVisitors.currentValue, item.ownlyVisitors.currentValue
  ), [item.ownlyReturningVisitors.currentValue, item.ownlyVisitors.currentValue]);

  const newLeadsRate = useMemo(() => calculateRate(
    item.newLeads.currentValue, item.totalVisitors.currentValue
  ), [item.newLeads.currentValue, item.totalVisitors.currentValue]);

  const websiteNewLeadsRate = useMemo(() => calculateRate(
    item.websiteNewLeads.currentValue, item.websiteVisitors.currentValue
  ), [item.websiteNewLeads.currentValue, item.websiteVisitors.currentValue]);

  const ownlyNewLeadsRate = useMemo(() => calculateRate(
    item.ownlyNewLeads.currentValue, item.ownlyVisitors.currentValue
  ), [item.ownlyNewLeads.currentValue, item.ownlyVisitors.currentValue]);

  const reservationCompletedRate = useMemo(() => calculateRate(
    item.reservationCompleted.currentValue, item.reservationStarted.currentValue
  ), [item.reservationCompleted.currentValue, item.reservationStarted.currentValue]);

  const verificationCompletedRate = useMemo(() => calculateRate(
    item.verificationCompleted.currentValue, item.verificationStarted.currentValue
  ), [item.verificationCompleted.currentValue, item.verificationStarted.currentValue]);

  return (
    <>
      <tr className="text-center">
        <td className="text-left text-nowrap">
          {expanded ? (
            <FaChevronDown role="button" aria-hidden="true" className="col-gray-500" onClick={onToggle} />
          ) : (
            <FaChevronRight role="button" aria-hidden="true" className="col-gray-500" onClick={onToggle} />
          )}
          <div
            role="button"
            aria-hidden
            className="col-primary-1 text-decoration-underline d-inline-block ml-2"
            onClick={() => onCompanyClick(item.company)}
          >
            {item.company.name}
          </div>
        </td>
        <td className={getCellColor(item.totalVisitors.percentageChange)}>
          {formatNumber(item.totalVisitors.currentValue)}
        </td>
        <td className={getCellColor(item.totalReturningVisitors.percentageChange)}>
          {formatNumber(item.totalReturningVisitors.currentValue)}
          <span className="ml-1">{displayRatePercentage(returningVisitorsRate)}</span>
        </td>
        <td className={getCellColor(item.newLeads.percentageChange)}>
          {formatNumber(item.newLeads.currentValue)}
          <span className="ml-1">{displayRatePercentage(newLeadsRate)}</span>
        </td>
        <td className={getCellColor(item.returningLeads.percentageChange)}>
          {formatNumber(item.returningLeads.currentValue)}
        </td>
        <td className={getCellColor(item.reservationStarted.percentageChange)}>
          {formatNumber(item.reservationStarted.currentValue)}
        </td>
        <td className={getCellColor(item.reservationCompleted.percentageChange)}>
          {formatNumber(item.reservationCompleted.currentValue)}
          <span className="ml-1">{displayRatePercentage(reservationCompletedRate)}</span>
        </td>
        <td className={getCellColor(item.verificationStarted.percentageChange)}>
          {formatNumber(item.verificationStarted.currentValue)}
        </td>
        <td className={getCellColor(item.verificationCompleted.percentageChange)}>
          {formatNumber(item.verificationCompleted.currentValue)}
          <span className="ml-1">{displayRatePercentage(verificationCompletedRate)}</span>
        </td>
      </tr>

      {expanded && (
        <>
          <tr className="text-center bg-gray-50">
            <td className="text-right font-weight-500">{i18n.t('website', i18nOpts)}</td>
            <td className={getCellColor(item.websiteVisitors.percentageChange)}>
              {formatNumber(item.websiteVisitors.currentValue)}
            </td>
            <td className={getCellColor(item.websiteReturningVisitors.percentageChange)}>
              {formatNumber(item.websiteReturningVisitors.currentValue)}
              <span className="ml-1">{displayRatePercentage(websiteReturningVisitorsRate)}</span>
            </td>
            <td className={getCellColor(item.websiteNewLeads.percentageChange)}>
              {formatNumber(item.websiteNewLeads.currentValue)}
              <span className="ml-1">{displayRatePercentage(websiteNewLeadsRate)}</span>
            </td>
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
          <tr className="text-center bg-gray-50">
            <td className="text-right font-weight-500">{i18n.t('ownly', i18nOpts)}</td>
            <td className={getCellColor(item.ownlyVisitors.percentageChange)}>
              {formatNumber(item.ownlyVisitors.currentValue)}
            </td>
            <td className={getCellColor(item.ownlyReturningVisitors.percentageChange)}>
              {formatNumber(item.ownlyReturningVisitors.currentValue)}
              <span className="ml-1">{displayRatePercentage(ownlyReturningVisitorsRate)}</span>
            </td>
            <td className={getCellColor(item.ownlyNewLeads.percentageChange)}>
              {formatNumber(item.ownlyNewLeads.currentValue)}
              <span className="ml-1">{displayRatePercentage(ownlyNewLeadsRate)}</span>
            </td>
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
        </>
      )}
    </>
  );
};

ListItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
