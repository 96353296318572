import React from 'react';
import { Button } from 'reactstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/all';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import momentTz from 'moment-timezone';

import styles from './DateRangeNavigator.module.scss';
import { dateRanges } from '../../utils';

const DateRangeNavigator = ({
  dateRange, selectedRange, onNavigate, maxDate
}) => {
  const canNavigateNext = () => {
    const [, end] = dateRange;
    return momentTz(end).isBefore(maxDate);
  };

  const handleNavigate = (direction) => {
    if (direction === 'next' && !canNavigateNext()) return;

    const rangeConfig = dateRanges[selectedRange];
    if (!rangeConfig) return;

    const newRange = rangeConfig.navigate(dateRange, direction);
    onNavigate(newRange);
  };

  return (
    <div className="d-flex gap-2">
      <Button
        color="primary"
        onClick={() => handleNavigate('prev')}
        className={classNames(styles.button, styles.prevButton)}
      >
        <FaChevronLeft />
      </Button>
      <Button
        color="primary"
        onClick={() => handleNavigate('next')}
        className={classNames(styles.button, styles.nextButton)}
        disabled={!canNavigateNext()}
      >
        <FaChevronRight />
      </Button>
    </div>
  );
};

DateRangeNavigator.propTypes = {
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  selectedRange: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired,
  maxDate: PropTypes.instanceOf(Date)
};

DateRangeNavigator.defaultProps = {
  maxDate: momentTz().startOf('day').toDate()
};

export default DateRangeNavigator;
