import React, { useState } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';

import {
  getLastSevenDaysRange,
  setDaysRangeInput
} from '../../../utils/dateUtils';
import VisitorLeadsTable from './VisitorLeadsTable';
import DateFilter from '../../common/DateFilter';
import { dateRanges, getCustomDateRanges } from './utils';
import DateRangeNavigator from './components/DateRangeNavigator';

const i18nOpts = { scope: 'components.admin.masterAdminDashboard.index' };

const MasterAdminDashboard = () => {
  const [selectedRange, setSelectedRange] = useState('Last 7 Days');
  const [dateRange, setDateRange] = useState(getLastSevenDaysRange());
  const [customDateRange, setCustomDateRange] = useState(
    () => setDaysRangeInput(getLastSevenDaysRange())
  );

  const [previousCustomDateRange, setPreviousCustomDateRange] = useState(() => {
    const [startDate] = getLastSevenDaysRange();

    const previousStartDate = new Date(startDate);
    const previousEndDate = new Date(startDate);
    previousStartDate.setDate(startDate.getDate() - 7);
    previousEndDate.setDate(startDate.getDate() - 1);

    return setDaysRangeInput([previousStartDate, previousEndDate]);
  });

  const updatePreviousCustomRange = (rangeKey, currentRange) => {
    const rangeConfig = dateRanges[rangeKey];

    let newRange = { startDate: null, endDate: null };
    if (rangeConfig) {
      newRange = setDaysRangeInput(rangeConfig.navigate(currentRange, 'prev'));
    }

    setPreviousCustomDateRange(newRange);
  };

  const updateDateRange = (rangeKey, newRange) => {
    updatePreviousCustomRange(rangeKey, newRange);

    setDateRange(newRange);
    setCustomDateRange(setDaysRangeInput(newRange));
  };

  const onPickedDate = (event, picker) => {
    const rangeKey = picker.chosenLabel;
    const newRange = [
      picker.startDate.startOf('day').toDate(),
      picker.endDate.endOf('day').toDate()
    ];

    setSelectedRange(rangeKey);
    updateDateRange(rangeKey, newRange);
  };

  const handleNavigate = (newRange) => {
    updateDateRange(selectedRange, newRange);
  };

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />
      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>
      </div>

      <div className="d-flex align-items-center gap-3 mb-4">
        <DateFilter
          dateRange={dateRange}
          onDatePicked={onPickedDate}
          customRanges={getCustomDateRanges(dateRanges)}
        />

        <DateRangeNavigator
          dateRange={dateRange}
          selectedRange={selectedRange}
          onNavigate={handleNavigate}
        />
      </div>

      <VisitorLeadsTable
        customDateRange={customDateRange}
        previousCustomDateRange={previousCustomDateRange}
      />
    </div>
  );
};

MasterAdminDashboard.propTypes = {};

MasterAdminDashboard.defaultProps = {};

export default MasterAdminDashboard;
