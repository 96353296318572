import momentTz from 'moment-timezone';

export const dateRanges = {
  'Last 7 Days': {
    range: [
      momentTz().subtract(6, 'days').startOf('day').toDate(),
      momentTz().endOf('day').toDate()
    ],
    navigate: (currentRange, direction) => {
      const [start, end] = currentRange;
      if (direction === 'prev') {
        return [
          momentTz(start).subtract(7, 'days').startOf('day').toDate(),
          momentTz(end).subtract(7, 'days').endOf('day').toDate()
        ];
      }
      return [
        momentTz(start).add(7, 'days').startOf('day').toDate(),
        momentTz(end).add(7, 'days').endOf('day').toDate()
      ];
    }
  },
  'Weekly': {
    range: [
      momentTz().startOf('week').toDate(),
      momentTz().endOf('week').toDate()
    ],
    navigate: (currentRange, direction) => {
      const [start, end] = currentRange;
      if (direction === 'prev') {
        return [
          momentTz(start).subtract(1, 'week').startOf('week').toDate(),
          momentTz(end).subtract(1, 'week').endOf('week').toDate()
        ];
      }
      return [
        momentTz(start).add(1, 'week').startOf('week').toDate(),
        momentTz(end).add(1, 'week').endOf('week').toDate()
      ];
    }
  },
  'Monthly': {
    range: [
      momentTz().startOf('month').toDate(),
      momentTz().endOf('month').toDate()
    ],
    navigate: (currentRange, direction) => {
      const [start, end] = currentRange;
      if (direction === 'prev') {
        return [
          momentTz(start).subtract(1, 'month').startOf('month').toDate(),
          momentTz(end).subtract(1, 'month').endOf('month').toDate()
        ];
      }
      return [
        momentTz(start).add(1, 'month').startOf('month').toDate(),
        momentTz(end).add(1, 'month').endOf('month').toDate()
      ];
    }
  },
  'This Year': {
    range: [
      momentTz().startOf('year').toDate(),
      momentTz().endOf('year').toDate()
    ],
    navigate: (currentRange, direction) => {
      const [start, end] = currentRange;
      if (direction === 'prev') {
        return [
          momentTz(start).subtract(1, 'year').startOf('year').toDate(),
          momentTz(end).subtract(1, 'year').endOf('year').toDate()
        ];
      }
      return [
        momentTz(start).add(1, 'year').startOf('year').toDate(),
        momentTz(end).add(1, 'year').endOf('year').toDate()
      ];
    }
  },
  'Last Year': {
    range: [
      momentTz().subtract(1, 'year').startOf('year').toDate(),
      momentTz().subtract(1, 'year').endOf('year').toDate()
    ],
    navigate: (currentRange, direction) => {
      const [start, end] = currentRange;
      if (direction === 'prev') {
        return [
          momentTz(start).subtract(1, 'year').startOf('year').toDate(),
          momentTz(end).subtract(1, 'year').endOf('year').toDate()
        ];
      }
      return [
        momentTz(start).add(1, 'year').startOf('year').toDate(),
        momentTz(end).add(1, 'year').endOf('year').toDate()
      ];
    }
  },
  'Custom Range': {
    range: [
      momentTz().startOf('day').toDate(),
      momentTz().endOf('day').toDate()
    ],
    navigate: (currentRange, direction) => {
      const [start, end] = currentRange;
      const diffDays = momentTz(end).diff(momentTz(start), 'days');

      if (direction === 'prev') {
        return [
          momentTz(start).subtract(diffDays + 1, 'days').startOf('day').toDate(),
          momentTz(start).subtract(1, 'day').endOf('day').toDate()
        ];
      }
      return [
        momentTz(end).add(1, 'day').startOf('day').toDate(),
        momentTz(end).add(diffDays + 1, 'days').endOf('day').toDate()
      ];
    }
  }
};

export function getCustomDateRanges() {
  return Object.entries(dateRanges).reduce((acc, [key, value]) => {
    if (['Last 7 Days', 'Weekly', 'Monthly', 'This Year', 'Last Year'].includes(key)) {
      acc[key] = value.range;
    }
    return acc;
  }, {});
}
