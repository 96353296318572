import v from 'voca';

import store from '../store';
import {
  EMBEDDED_QP_PREFIX,
  EMBEDDED_RESERVE_HOME_PARAM,
  EMBEDDED_SALES_LEAD_PARAM,
  EMBEDDED_UNIT_PREFIX,
  SellerTypes
} from '../constants';
import { getUnitEmbeddedBaseUrl } from './unitUtils';
import { getQPEmbeddedBaseUrl } from './quickPossessionUtils';

const GET_VERIFIED_PATH = '/getVerified';
const RENTAL_VERIFIED_PATH = '/rentalVerified';
const SALES_TEAM_PREFIX = 't-';

/**
 * Get verified URL
 * @param hashId: Sales Rep Hash ID or Sales Team Hash ID
 * @param isRental
 * @returns {string}
 */
export function buildVerifiedInvitationUrl(hashId, isRental) {
  const { currentCompany } = store.getState().companies;
  const domain = currentCompany.domains[0];
  if (v.isBlank(domain)) return '';

  const basePath = isRental ? RENTAL_VERIFIED_PATH : GET_VERIFIED_PATH;

  const path = [basePath, hashId].join('/');
  const url = new URL(path, domain);

  return url.toString();
}

/**
 * Get reserve now URL
 * @param salesLeadHashId: Sales Rep Hash ID or Sales Team Hash ID
 * @param homeHashId Unit Hash ID or Sales Hash ID
 * @param isUnit Indicate if it is unit or quick possession
 * @returns {string}
 */
export function buildReserveNowInvitationUrl(salesLeadHashId, homeHashId, isUnit) {
  const { currentCompany } = store.getState().companies;
  const domain = currentCompany.domains[0];
  if (v.isBlank(domain)) return '';

  const basePath = isUnit ? '/unit' : '/quickPossession';

  const path = [basePath, homeHashId, 'reserve', salesLeadHashId].join('/');
  const url = new URL(path, domain);

  return url.toString();
}

export function getPublicTeamHashId(team) {
  return `${SALES_TEAM_PREFIX}${team.hashId}`;
}

export function isSalesRep(seller) {
  // eslint-disable-next-line no-underscore-dangle
  return seller.__typename === SellerTypes.SALES_REP;
}

export function isSalesTeam(seller) {
  // eslint-disable-next-line no-underscore-dangle
  return seller.__typename === SellerTypes.SALES_TEAM;
}

export function buildEmbeddedReserveNowInvitationUrl(salesLeadHashId, invitedHome, isUnit) {
  let baseUrl;
  if (isUnit) baseUrl = getUnitEmbeddedBaseUrl(invitedHome);
  else baseUrl = getQPEmbeddedBaseUrl(invitedHome);

  if (v.isBlank(baseUrl)) return '';

  const url = new URL(baseUrl);
  const reserveHomeParamValue = `${isUnit ? EMBEDDED_UNIT_PREFIX : EMBEDDED_QP_PREFIX}${invitedHome.hashId}`;
  url.searchParams.set(EMBEDDED_RESERVE_HOME_PARAM, reserveHomeParamValue);
  url.searchParams.set(EMBEDDED_SALES_LEAD_PARAM, salesLeadHashId);

  return url.toString();
}
