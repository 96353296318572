export function removeDomainFromURL(url) {
  return url.replace(/^.*\/\/[^\/]+/, '');
}

export function isValidURL(url) {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch {
    return false;
  }
}
