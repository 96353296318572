import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Collapse, FormGroup, Input, Label, Row
} from 'reactstrap';
import Switch from 'rc-switch';
import i18n from 'i18n-js';

import { parseBoolean } from '../../../../../../utils/parserUtils';
import { isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';
import InputError, { isInputInvalid } from '../../../../../ui/InputError';
import {
  BankVerificationProviders,
  IdVerificationProviders,
  PreApprovalVerificationMethods
} from '../../../../../../constants';
import { getBankVerificationProviderName, getIdVerificationProviderName } from '../../../../../../utils/enumUtils';
import { isCanadianCountry } from '../../../../Customers/Show/Overview/PropertyCard/utils';
import Workflows from './Workflows';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.workflow.index' };

const Workflow = ({ form, error, onTextChange }) => {
  const reserveNowSettings = form.reserveNowSettings || {};

  const onChangeSwitch = (name, checked) => {
    onTextChange({ target: { name, value: checked } });
  };

  const onChangeReserveNowSetting = (name, checked) => {
    const newReserveNowSettings = { ...reserveNowSettings, [name]: checked };
    if (name === 'enabled' && !checked) {
      newReserveNowSettings.enabledPriceNegotiation = false;
    }
    onTextChange({ target: { name: 'reserveNowSettings', value: newReserveNowSettings } });
  };

  const onChangeHomewiseSwitch = (checked) => {
    onTextChange({
      target: { name: 'preApprovalVerificationProvider', value: checked ? PreApprovalVerificationMethods.HOMEWISE : '' }
    });
  };

  const isMasterAdminRole = isMasterAdmin();
  const isTecSupportRole = isTecSupport();
  const canViewMasterSettings = isMasterAdminRole || isTecSupportRole;
  const isHomewiseEnabled = form.preApprovalVerificationProvider
    === PreApprovalVerificationMethods.HOMEWISE;

  return (
    <>
      {canViewMasterSettings && (
        <Row>
          <Col md={12} lg={9} xl={8}>
            <FormGroup row id="enableReserveNowContainer">
              <Label sm={5} className="py-0">{i18n.t('enableReserveNow', i18nOpts)}</Label>
              <Col sm={7}>
                <Switch
                  checked={parseBoolean(reserveNowSettings.enabled)}
                  onChange={(checked) => onChangeReserveNowSetting('enabled', checked)}
                />
              </Col>
            </FormGroup>

            <FormGroup row id="enableVerifiedContainer">
              <Label sm={5} className="py-0">{i18n.t('enableVerified', i18nOpts)}</Label>
              <Col sm={7}>
                <Switch
                  checked={parseBoolean(form.enableVerifiedBuyingPower)}
                  onChange={(checked) => onChangeSwitch('enableVerifiedBuyingPower', checked)}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
      )}

      {canViewMasterSettings && (<hr className="mt-0" />)}
      <Workflows form={form} onTextChange={onTextChange} />

      {canViewMasterSettings && (
        <>
          <Collapse isOpen={parseBoolean(reserveNowSettings.enabled)}>
            <hr className="mt-0" />
            <Row>
              <Col md={12} lg={9} xl={8}>
                <FormGroup row id="enabledPriceNegotiationContainer">
                  <Label sm={5} className="py-0">{i18n.t('enablePriceNegotiation', i18nOpts)}</Label>
                  <Col sm={7}>
                    <Switch
                      checked={parseBoolean(reserveNowSettings.enabledPriceNegotiation)}
                      onChange={(checked) => onChangeReserveNowSetting('enabledPriceNegotiation', checked)}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row id="enabledReportsContainer">
                  <Label sm={5} className="py-0">{i18n.t('enableReports', i18nOpts)}</Label>
                  <Col sm={7}>
                    <Switch
                      checked={parseBoolean(reserveNowSettings.enabledReports)}
                      onChange={(checked) => onChangeReserveNowSetting('enabledReports', checked)}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Collapse>

          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row id="enableEmbeddedContainer">
                <Label sm={5} className="py-0">{i18n.t('enableEmbedded', i18nOpts)}</Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableEmbedded)}
                    onChange={(checked) => onChangeSwitch('enableEmbedded', checked)}
                  />
                </Col>
              </FormGroup>

              <Collapse isOpen={parseBoolean(form.enableEmbedded)}>
                <FormGroup row id="embeddedBaseUrlContainer">
                  <Label sm={5}>{i18n.t('embeddedBaseUrl', i18nOpts)}</Label>
                  <Col sm={7}>
                    <Input
                      type="text"
                      name="embeddedBaseUrl"
                      id="embeddedBaseUrl"
                      value={form.embeddedBaseUrl || ''}
                      onChange={onTextChange}
                      invalid={isInputInvalid(error, 'embeddedBaseUrl')}
                      placeholder={i18n.t('placeholders.embeddedBaseUrl', i18nOpts)}
                    />
                    <InputError name="embeddedBaseUrl" error={error} />
                  </Col>
                </FormGroup>
              </Collapse>
            </Col>
          </Row>

          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row id="enableIdVerificationContainer">
                <Label sm={5} className="py-0">{i18n.t('enableIdVerification', i18nOpts)}</Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableIdVerification)}
                    onChange={(checked) => onChangeSwitch('enableIdVerification', checked)}
                  />
                </Col>
              </FormGroup>

              <Collapse isOpen={parseBoolean(form.enableIdVerification)}>
                <FormGroup row id="idVerificationProviderContainer">
                  <Label sm={5}>{i18n.t('idVerificationProvider', i18nOpts)}</Label>
                  <Col sm={7}>
                    <Input
                      type="select"
                      name="idVerificationProvider"
                      id="idVerificationProvider"
                      value={form.idVerificationProvider || ''}
                      onChange={onTextChange}
                    >
                      {
                        Object.values(IdVerificationProviders)
                          .map((idVerificationProvider) => (
                            <option key={`id-verification-provider-${idVerificationProvider}`} value={idVerificationProvider}>
                              {getIdVerificationProviderName(idVerificationProvider)}
                            </option>
                          ))
                      }
                    </Input>
                  </Col>
                </FormGroup>
              </Collapse>

              <FormGroup row id="enablePreApprovalVerificationContainer">
                <Label sm={5} className="py-0">{i18n.t('enablePreApprovalVerification', i18nOpts)}</Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enablePreApprovalVerification)}
                    onChange={(checked) => onChangeSwitch('enablePreApprovalVerification', checked)}
                  />
                </Col>
              </FormGroup>

              {isCanadianCountry(form.countryCode || form.country) && (
                <Collapse isOpen={parseBoolean(form.enablePreApprovalVerification)}>
                  <FormGroup row id="enableHomewiseContainer">
                    <Label sm={5} className="py-0">{i18n.t('enableHomewise', i18nOpts)}</Label>
                    <Col sm={7}>
                      <Switch
                        checked={isHomewiseEnabled}
                        onChange={(checked) => onChangeHomewiseSwitch(checked)}
                      />
                    </Col>
                  </FormGroup>

                  <Collapse isOpen={isHomewiseEnabled}>
                    <FormGroup row id="enableDemoModeForHomewiseContainer">
                      <Label sm={5} className="py-0">{i18n.t('enableDemoModeForHomewise', i18nOpts)}</Label>
                      <Col sm={7}>
                        <Switch
                          checked={parseBoolean(form.enableDemoModeForHomewise)}
                          onChange={(checked) => onChangeSwitch('enableDemoModeForHomewise', checked)}
                        />
                      </Col>
                    </FormGroup>
                  </Collapse>
                </Collapse>
              )}

              <FormGroup row id="bankVerificationProviderContainer">
                <Label sm={5}>{i18n.t('bankVerificationProvider', i18nOpts)}</Label>
                <Col sm={7}>
                  <Input
                    type="select"
                    name="bankVerificationProvider"
                    id="bankVerificationProvider"
                    value={form.bankVerificationProvider || ''}
                    onChange={onTextChange}
                  >
                    {
                      Object.values(BankVerificationProviders)
                        .map((bankVerificationProvider) => (
                          <option key={`bank-verification-provider-${bankVerificationProvider}`} value={bankVerificationProvider}>
                            {getBankVerificationProviderName(bankVerificationProvider)}
                          </option>
                        ))
                    }
                  </Input>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row id="enableContactPhoneRequiredContainer">
                <Label sm={5} className="py-0">{i18n.t('phoneNumberMandatory', i18nOpts)}</Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableContactPhoneRequired)}
                    onChange={(checked) => onChangeSwitch('enableContactPhoneRequired', checked)}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row id="enablePickingALotContainer">
                <Label sm={5} className="py-0">
                  {i18n.t('enableLotSelectionFirst', i18nOpts)}
                  <span className="help-text">{i18n.t('helpTexts.enableLotSelectionFirst', i18nOpts)}</span>
                </Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enablePickingALot)}
                    onChange={(checked) => onChangeSwitch('enablePickingALot', checked)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row id="enableSampleDataContainer">
                <Label sm={5} className="py-0">
                  {i18n.t('enableDemoSampleData', i18nOpts)}
                  <span className="help-text">{i18n.t('helpTexts.enableDemoSampleData', i18nOpts)}</span>
                </Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableSampleData)}
                    onChange={(checked) => onChangeSwitch('enableSampleData', checked)}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row id="enableAirMilesForReserveNowContainer">
                <Label sm={5} className="py-0">{i18n.t('enableAirMilesForReserveNow', i18nOpts)}</Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableAirMilesForReserveNow)}
                    onChange={(checked) => onChangeSwitch('enableAirMilesForReserveNow', checked)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row id="enableAirMilesForVerifiedContainer">
                <Label sm={5} className="py-0">{i18n.t('enableAirMilesForVerified', i18nOpts)}</Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableAirMilesForVerified)}
                    onChange={(checked) => onChangeSwitch('enableAirMilesForVerified', checked)}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row id="allowSaveHomeWithoutVerificationContainer">
                <Label sm={5} className="py-0">
                  {i18n.t('allowSaveHomeWithoutVerification', i18nOpts)}
                  <span className="help-text">{i18n.t('helpTexts.allowSaveHomeWithoutVerification', i18nOpts)}</span>
                </Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.allowSaveHomeWithoutVerification)}
                    onChange={(checked) => onChangeSwitch('allowSaveHomeWithoutVerification', checked)}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

Workflow.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func
};

Workflow.defaultProps = {
  error: null,
  onTextChange: () => {}
};

export default Workflow;
